var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("h1", [_vm._v("URLリンク（開発用）")]),
      _c("router-link", { attrs: { to: "/" } }, [_vm._v("TOPページ")]),
      _c("br"),
      _c("router-link", { attrs: { to: "/rocky/search" } }, [
        _vm._v("検索条件入力")
      ]),
      _c("br"),
      _c("router-link", { attrs: { to: "/rocky/rocky_list" } }, [
        _vm._v("検索結果（岩場一覧）")
      ]),
      _c("br"),
      _c("router-link", { attrs: { to: "/record/top" } }, [
        _vm._v("クライミング記録")
      ]),
      _c("br"),
      _c("router-link", { attrs: { to: "/record/view/prefectures_list" } }, [
        _vm._v("登攀状況 都道府県")
      ]),
      _c("br"),
      _c("router-link", { attrs: { to: "/record/view/rocky_list" } }, [
        _vm._v("登攀状況 岩場")
      ]),
      _c("br"),
      _c("router-link", { attrs: { to: "/record/view/climb_route_list" } }, [
        _vm._v("登攀状況 登攀したルート一覧")
      ]),
      _c("br"),
      _c("router-link", { attrs: { to: "/record/view/complete_route_list" } }, [
        _vm._v("登攀状況 完登したルート一覧")
      ]),
      _c("br"),
      _c("router-link", { attrs: { to: "/record/check_in/gps_search" } }, [
        _vm._v("岩場チェックイン")
      ]),
      _c("br"),
      _c("router-link", { attrs: { to: "/record/check_in/select_rocky" } }, [
        _vm._v("チェックイン対象岩場")
      ]),
      _c("br"),
      _c("router-link", { attrs: { to: "/record/create/select_rocky" } }, [
        _vm._v("記録対象の岩場")
      ]),
      _c("br"),
      _c("router-link", { attrs: { to: "/record/create/input" } }, [
        _vm._v("登攀記録入力")
      ]),
      _c("br"),
      _c("router-link", { attrs: { to: "/record/create/edit" } }, [
        _vm._v("登攀記録 修正・削除")
      ]),
      _c("br"),
      _c("router-link", { attrs: { to: "/register_user/send_auth" } }, [
        _vm._v("ユーザ登録")
      ]),
      _c("br"),
      _c("router-link", { attrs: { to: "/user/edit" } }, [
        _vm._v("ユーザー情報の編集")
      ]),
      _c("br"),
      _c("router-link", { attrs: { to: "/user/password" } }, [
        _vm._v("パスワード変更")
      ]),
      _c("br"),
      _c("router-link", { attrs: { to: "/user/mail/send_auth" } }, [
        _vm._v("メールアドレス変更（認証）")
      ]),
      _c("br"),
      _c("router-link", { attrs: { to: "/user/password_reset/reset" } }, [
        _vm._v("パスワード再設定")
      ]),
      _c("br"),
      _c("router-link", { attrs: { to: "/unsubscribe/consent" } }, [
        _vm._v("退会")
      ]),
      _c("br"),
      _c("router-link", { attrs: { to: "/inquiry_form/input" } }, [
        _vm._v("問い合わせ入力")
      ]),
      _c("br"),
      _c("router-link", { attrs: { to: "/feed_back/input" } }, [
        _vm._v("フィードバック入力")
      ]),
      _c("br"),
      _c("router-link", { attrs: { to: "/info/user_guide" } }, [
        _vm._v("利用ガイド")
      ]),
      _c("br"),
      _c("router-link", { attrs: { to: "/info/faq" } }, [_vm._v("FAQ")]),
      _c("br"),
      _c("router-link", { attrs: { to: "/info/terms" } }, [_vm._v("利用規約")]),
      _c("br"),
      _c("router-link", { attrs: { to: "/info/company" } }, [
        _vm._v("運営会社")
      ]),
      _c("br"),
      _c("router-link", { attrs: { to: "/info/legalnotice" } }, [
        _vm._v("免責事項")
      ]),
      _c("br"),
      _c("router-link", { attrs: { to: "/info/law" } }, [
        _vm._v("特定商取引法の表示")
      ]),
      _c("br"),
      _c("router-link", { attrs: { to: "/info/privacy" } }, [
        _vm._v("プライバシーポリシー")
      ]),
      _c("br"),
      _c("router-link", { attrs: { to: "/login" } }, [_vm._v("ログイン")])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }